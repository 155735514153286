var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "top-bar"
  }, [_vm._v(" 车位详情 "), _c('i', {
    staticClass: "el-icon-close",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  })]), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "content"
  }, [_vm.buildingInfo.floorName || _vm.buildingInfo.areaName || _vm.buildingInfo.parkingPlaceNo ? _c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.buildingInfo.floorName) + " - " + _vm._s(_vm.buildingInfo.areaName) + " - " + _vm._s(_vm.buildingInfo.parkingPlaceNo) + " 号车位 ")]) : _vm._e(), _vm.buildingInfo.id ? _c('div', {
    staticClass: "info-cell"
  }, [_c('div', {
    staticClass: "info-cell-title"
  }, [_vm._v(" 基本信息 "), _c('span', {
    staticClass: "status",
    class: {
      grey: !_vm.buildingInfo.available
    }
  }, [_vm._v(" " + _vm._s(_vm.buildingInfo.available ? "上架中" : "已下架") + " ")])]), _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("销售状态：")]), _c('div', {
    staticClass: "value",
    style: {
      color: _vm.carStateObj.color
    }
  }, [_vm._v(" " + _vm._s(_vm.carStateObj.name) + " ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("开盘活动：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.buildingInfo.openQuotationName))])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("车位类型：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.buildingInfo.parkingPlaceTypeDesc))])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("建筑面积：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.buildingInfo.buildingArea) + "㎡")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("实得面积：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.buildingInfo.actualArea ? "".concat(_vm.buildingInfo.actualArea, "\u33A1") : "-") + " ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("计划收款额：")]), _vm.buildingInfo.discountAfterPrice ? _c('div', {
    staticClass: "value red"
  }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.buildingInfo.discountAfterPrice * 10000)) + " ")]) : _c('div', {
    staticClass: "value red"
  }, [_vm._v("价格待定")])]), _vm.buildingInfo.description ? _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("备注信息：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.buildingInfo.description))])]) : _vm._e()]), _vm.buildingInfo.discountPrice1 || _vm.buildingInfo.discountPrice2 || _vm.buildingInfo.discountPrice3 ? _c('div', {
    staticClass: "cell"
  }, [_vm.buildingInfo.discountPrice1 ? _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("优惠价格1：")]), _c('div', {
    staticClass: "value red"
  }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.buildingInfo.discountPrice1)) + " ")])]) : _vm._e(), _vm.buildingInfo.discountPrice2 ? _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label red"
  }, [_vm._v("优惠价格2：")]), _c('div', {
    staticClass: "value red"
  }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.buildingInfo.discountPrice2)) + " ")])]) : _vm._e(), _vm.buildingInfo.discountPrice3 ? _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label red"
  }, [_vm._v("优惠价格3：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.buildingInfo.discountPrice3)) + " ")])]) : _vm._e(), _vm._l(_vm.promotions, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "mt10"
    }, [_c('div', {
      staticClass: "item"
    }, [_c('div', {
      staticClass: "label"
    }, [_vm._v("营销礼包：")]), item.subscriptionTotalPrice ? _c('div', {
      staticClass: "value red"
    }, [_vm._v(" ￥" + _vm._s(_vm._f("toThousands")(item.subscriptionTotalPrice)) + " ")]) : _c('div', {
      staticClass: "value red"
    }, [_vm._v("价格待定")])]), _c('div', {
      staticClass: "item"
    }, [_c('div', {
      staticClass: "label"
    }), _c('div', {
      staticClass: "value"
    }, [_vm._v(_vm._s(item.promotionalName))])])]);
  })], 2) : _vm._e(), _vm.collectionUsers.length ? _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("收藏用户")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.collectionUsers.length) + "个")])]), _c('div', {
    staticClass: "user-list mt20"
  }, _vm._l(_vm.collectionUsersList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "user-cell",
      staticStyle: {
        "cursor": "pointer"
      }
    }, [_c('el-popover', {
      attrs: {
        "placement": "top-start",
        "width": "220",
        "trigger": "hover"
      }
    }, [_c('div', [_c('p', [_vm._v("认购客户：" + _vm._s(item.name))]), _c('p', [_vm._v("联系电话：" + _vm._s(_vm._f("formatPhone")(item.cellphone)))])]), _c('img', {
      staticClass: "user-cell",
      attrs: {
        "slot": "reference",
        "src": item.avatarUrl
      },
      slot: "reference"
    })])], 1);
  }), 0), _vm.collectionUsers.length > 16 && _vm.collectionUsersList.length <= 16 ? _c('div', {
    staticClass: "load-more",
    on: {
      "click": _vm.showMoreUser
    }
  }, [_vm._v(" 加载更多 ")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.subscriptionParkingBookingOrder.id ? _c('div', {
    staticClass: "info-cell"
  }, [_c('div', {
    staticClass: "info-cell-title"
  }, [_vm._v("认购订单")]), _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("认购单号")]), _c('div', {
    staticClass: "value"
  }, [_c('svg-icon', {
    staticClass: "copy-icon pointer",
    attrs: {
      "icon-class": "copy"
    },
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.subscriptionParkingBookingOrder.orderNo);
      }
    }
  }), _vm._v(" " + _vm._s(_vm.subscriptionParkingBookingOrder.orderNo) + " ")], 1)]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("认购总额")]), _c('div', {
    staticClass: "value red"
  }, [_vm._v(" ¥" + _vm._s(_vm.subscriptionParkingBookingOrder.orderTotalPrice) + " ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("优惠活动")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.subscriptionParkingBookingOrder.giftBagName || (_vm.subscriptionParkingBookingOrder.orderTotalPriceDesc == "认购总额" ? "无" : _vm.subscriptionParkingBookingOrder.orderTotalPriceDesc)) + " ")])]), _vm._m(0)]), _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("认购金")]), _c('div', {
    staticClass: "value red"
  }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.subscriptionParkingBookingOrder.orderPrice)) + " ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("支付方式")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.subscriptionParkingBookingOrder.orderWayDesc) + " ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("认购时间")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.subscriptionParkingBookingOrder.inDate) + " ")])])]), _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("认购人")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.subscriptionParkingBookingOrder.customerName) + " ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("身份证号")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.subscriptionParkingBookingOrder.customerIdcard) + " ")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("手机号码")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm._f("formatPhone")(_vm.subscriptionParkingBookingOrder.customerPhone)) + " ")])])])]) : _vm._e(), _vm.bidTargetInfo.biddingAmount ? _c('div', {
    staticClass: "info-cell"
  }, [_c('div', {
    staticClass: "info-cell-title"
  }, [_vm._v("正在竞拍")]), _c('div', {
    staticClass: "cell"
  }, [_vm.bidTargetInfo.biddingAmount ? _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("起拍价：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.bidTargetInfo.biddingAmount)) + " ")])]) : _vm._e(), _vm.bidTargetInfo.biddingStepAmount ? _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("加价幅度：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.bidTargetInfo.biddingStepAmount)) + " ")])]) : _vm._e(), _vm.bidTargetInfo.delay ? _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("延时周期：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.bidTargetInfo.delay) + "分钟")])]) : _vm._e(), _vm.bidTargetInfo.ensureAmount ? _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("保证金：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" ¥" + _vm._s(_vm._f("toThousands")(_vm.bidTargetInfo.ensureAmount)) + " ")])]) : _vm._e(), _vm.bidTargetInfo.startDate ? _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("开始时间：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.bidTargetInfo.startDate))])]) : _vm._e(), _vm.bidTargetInfo.endDelayDate ? _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("结束时间：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.bidTargetInfo.endDelayDate))])]) : _vm._e()])]) : _vm._e()]), _c('div', {
    staticClass: "footer"
  }, [_c('el-button', {
    staticClass: "flex1",
    attrs: {
      "size": "medium",
      "type": "info"
    },
    on: {
      "click": _vm.parkingPlaceBatchAvailable
    }
  }, [_vm._v(" " + _vm._s(_vm.buildingInfo.available ? "下架" : "上架") + " ")]), _c('el-button', {
    staticClass: "flex1",
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.openEdit
    }
  }, [_vm._v(" 编辑车位 ")])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("金融方案")]), _c('div', {
    staticClass: "value"
  }, [_vm._v("已申请")])]);

}]

export { render, staticRenderFns }