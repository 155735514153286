<template>
  <div class="x">
    <openingMenu class="mr10" />
    <openingBlock />
  </div>
</template>

<script>
import {} from "@/api/openQuotationActivity";
import openingMenu from "./components/openingMenu";
import openingBlock from "./components/openingBlock";
export default {
  components: {
    openingMenu,
    openingBlock
  },
  data() {
    return {};
  }
};
</script>
