var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "f-dialog",
    attrs: {
      "visible": _vm.visible,
      "title": _vm.title,
      "close-on-click-modal": false,
      "before-close": _vm.close,
      "width": "605px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "form",
    staticClass: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "102px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "开盘活动：",
      "prop": "openQuotationId",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "size": "medium"
    },
    on: {
      "change": _vm.getMarketActivity
    },
    model: {
      value: _vm.formData.openQuotationId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "openQuotationId", $$v);
      },
      expression: "formData.openQuotationId"
    }
  }, _vm._l(_vm.activityList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item.activityName,
        "value": item.id
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "类型:",
      "prop": "parkingPlaceType",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-radio-group', {
    attrs: {
      "size": "medium"
    },
    model: {
      value: _vm.formData.parkingPlaceType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "parkingPlaceType", $$v);
      },
      expression: "formData.parkingPlaceType"
    }
  }, _vm._l(_vm.carTypeList, function (item, index) {
    return _c('el-radio-button', {
      key: index,
      attrs: {
        "label": item.a
      }
    }, [_vm._v(" " + _vm._s(item.b) + " ")]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "楼层：",
      "prop": "floorName",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-autocomplete', {
    staticClass: "width",
    attrs: {
      "size": "medium",
      "fetch-suggestions": _vm.querySearchFloor,
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.floorName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "floorName", $$v);
      },
      expression: "formData.floorName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "区域：",
      "prop": "areaName",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-autocomplete', {
    staticClass: "width",
    attrs: {
      "size": "medium",
      "fetch-suggestions": _vm.querySearchArea,
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.areaName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "areaName", $$v);
      },
      expression: "formData.areaName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "车位号：",
      "prop": "parkingPlaceNo",
      "rules": [_vm.$formRules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "size": "medium",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.parkingPlaceNo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "parkingPlaceNo", $$v);
      },
      expression: "formData.parkingPlaceNo"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("号")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "建筑面积：",
      "prop": "buildingArea",
      "rules": [_vm.$formRules.required(), _vm.$formRules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.buildingArea,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "buildingArea", $$v);
      },
      expression: "formData.buildingArea"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("㎡")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "实得面积：",
      "prop": "actualArea",
      "rules": [_vm.$formRules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.actualArea,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "actualArea", $$v);
      },
      expression: "formData.actualArea"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("㎡")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "销售总价：",
      "prop": "discountAfterPrice",
      "rules": [_vm.$formRules.required(), _vm.$formRules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.discountAfterPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "discountAfterPrice", $$v);
      },
      expression: "formData.discountAfterPrice"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "描述：",
      "prop": "description"
    }
  }, [_c('div', [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 3,
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1)]), _c('div', {
    staticClass: "promotional-title"
  }, [_vm._v(" 营销活动"), _c('em', [_vm._v("空值或0值时，代表不支持该营销活动")])]), _c('el-form-item', {
    attrs: {
      "label": "优惠价1：",
      "prop": "discountPrice1",
      "rules": [_vm.$formRules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.discountPrice1,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "discountPrice1", $$v);
      },
      expression: "formData.discountPrice1"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "优惠价2：",
      "prop": "discountPrice2",
      "rules": [_vm.$formRules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.discountPrice2,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "discountPrice2", $$v);
      },
      expression: "formData.discountPrice2"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "优惠价3：",
      "prop": "discountPrice3",
      "rules": [_vm.$formRules.number]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.discountPrice3,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "discountPrice3", $$v);
      },
      expression: "formData.discountPrice3"
    }
  }, [_c('template', {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "营销礼包：",
      "prop": "promotionalIds"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.formData.promotionalIds,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promotionalIds", $$v);
      },
      expression: "formData.promotionalIds"
    }
  }, _vm._l(_vm.marketActivityList, function (item, index) {
    return _c('el-option', {
      key: index,
      attrs: {
        "label": item.giftBagName,
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.giftBagName) + " "), _c('span', {
      staticClass: "red"
    }, [_vm._v("¥" + _vm._s(item.giftBagPrice))])]);
  }), 1)], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保 存")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }