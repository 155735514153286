<template>
  <div class="openingBlock dao-main">
    <div class="header x3 aic">
      <p class="total">
        <span class="mr10">{{ carAreas.join("-") }}</span>
        共<span class="red">{{ total }}</span
        >个车位
      </p>
      <div class="x aic" v-show="!isEdit && blockList.length">
        <el-button icon="el-icon-refresh-left" size="medium" @click="getData">
          刷新车位
        </el-button>
        <el-select
          class="mw20 w150"
          size="medium"
          @change="getData"
          v-model="queryData.sort"
        >
          <el-option
            v-for="(item, index) in sortList"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-button type="primary" size="medium" @click="chooseItem">
          批量操作
        </el-button>
      </div>
      <el-button
        v-show="isEdit"
        type="primary"
        size="medium"
        @click="quitChoose"
      >
        退出选择
      </el-button>
    </div>
    <div class="block-box">
      <itemBlock
        v-for="(item, index) in blockList"
        :key="index"
        :options="item"
        :isEdit="isEdit"
        :checked="findId(item.id)"
        @itemCheck="itemCheck"
        @click.native="showInfo(item)"
      />
    </div>
    <div
      v-if="blockList.length"
      class="aic"
      :class="{ x3: isEdit, x2: !isEdit }"
    >
      <div class="x aic" v-show="isEdit">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
        >
          全选
        </el-checkbox>
        <el-select
          clearable
          :disabled="!idList.length"
          class="w120 ml8"
          size="medium"
          @change="selectState"
          v-model="salesStatus"
          placeholder="批量销控"
        >
          <el-option
            v-for="(item, index) in stateList"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          clearable
          :disabled="!idList.length"
          class="w120 ml8"
          size="medium"
          @change="parkingPlaceBatchAvailable"
          v-model="available"
          placeholder="上架/下架"
        >
          <el-option
            v-for="(item, index) in availableList"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          clearable
          v-show="carActiveId"
          :disabled="!idList.length"
          multiple
          collapse-tags
          class="w120 ml8 x1 aic overflow"
          size="medium"
          @change="parkingPlaceBatchPromotional"
          v-model="promotionalIdList"
          placeholder="设置营销"
        >
          <el-option
            v-for="(item, index) in marketList"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-select
          clearable
          :disabled="!idList.length"
          class="w120 ml8"
          size="medium"
          @change="parkingPlaceBatchOpenQuotation"
          v-model="openQuotationId"
          placeholder="关联开盘"
        >
          <el-option
            v-for="(item, index) in activityList"
            :label="item.name"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
        <el-button
          style="margin-left:8px;"
          size="medium "
          :disabled="!idList.length"
          @click="parkingPlaceBatchDelete"
        >
          <span class="red">批量删除</span>
        </el-button>
      </div>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="queryData.pageIndex"
        :page-size="queryData.pageSize"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-drawer
      size="375px"
      :visible.sync="showInfoFlag"
      :with-header="false"
      :show-close="false"
    >
      <itemInfo
        v-if="showInfoFlag"
        :id="currentInfo.id"
        @openEdit="openEdit"
        @close="showInfoFlag = false"
      />
    </el-drawer>
    <editCar :visible.sync="showEdit" :id="currentInfo.id" />
  </div>
</template>

<script>
import {
  parkingPlacePage,
  parkingPlaceSortTypeList,
  parkingPlaceSalesStatusList,
  parkingPlaceBatchSalesStatus,
  parkingPlaceBatchAvailable,
  parkingPlaceBatchOpenQuotation,
  parkingPlaceBatchPromotional,
  parkingPlaceBatchDelete
} from "@/api/opening";
import { getOpeningList } from "@/api/openQuotationActivity";
import { getMarketActivity } from "@/api/marketActivity";
import itemBlock from "./itemBlock";
import itemInfo from "./itemInfo";
import editCar from "./editCar";

export default {
  components: {
    itemBlock,
    itemInfo,
    editCar
  },
  data() {
    return {
      value: "",
      blockList: [],
      isEdit: false,
      showInfoFlag: false,
      currentInfo: {},
      queryData: {
        sort: "ID_ASC",
        pageIndex: 1,
        pageSize: 5 * 9
      },
      salesStatus: "",
      available: "",
      openQuotationId: "",
      promotionalIdList: [],
      idList: [],
      sortList: [],
      stateList: [],
      activityList: [],
      marketList: [],
      total: 0,
      showEdit: false,
      availableList: [
        {
          name: "上架",
          id: true
        },
        {
          name: "下架",
          id: false
        }
      ],
      isIndeterminate: false,
      checkAll: false
    };
  },
  computed: {
    openingCarQuery() {
      return this.$store.state.openingCarQuery;
    },
    refreshFlag() {
      return this.$store.state.refreshFlag;
    },
    carAreas() {
      return this.$store.state.carAreas;
    },
    carActiveId() {
      return this.$store.state.carActiveId;
    },
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  watch: {
    openingCarQuery: {
      deep: true,
      handler(e) {
        this.queryData = {
          ...this.queryData,
          ...e
        };
        this.getData();
      }
    },
    idList: {
      deep: true,
      handler() {
        this.changeCheckState();
      }
    },
    refreshFlag() {
      this.getData();
    },
    carActiveId() {
      this.getMarketActivity();
    }
  },
  created() {
    this.parkingPlaceSalesStatusList();
    this.parkingPlaceSortTypeList();
    this.getOpeningList();
  },
  methods: {
    initCheck() {
      this.salesStatus = "";
      this.available = "";
      this.promotionalIdList = [];
      this.openQuotationId = "";
    },
    handleCurrentChange(val) {
      this.queryData.pageIndex = val;
      this.getData();
    },
    handleCheckAllChange(val) {
      let idList = [];
      if (val) {
        idList = this.blockList.map(e => e.id);
      }
      this.idList = idList;
      this.initCheck();
    },
    changeCheckState() {
      const { blockList, idList } = this;
      if (idList.length) {
        if (idList.length < blockList.length) {
          this.checkAll = false;
          this.isIndeterminate = true;
        } else {
          this.isIndeterminate = false;
          this.checkAll = true;
        }
      } else {
        this.checkAll = false;
        this.isIndeterminate = false;
      }
    },
    async parkingPlaceSalesStatusList() {
      const res = await parkingPlaceSalesStatusList();
      let list = res || [];
      this.stateList = this.$filterListTOName(list, { id: "a", name: "b" });
    },
    async getData() {
      if (!this.queryData.areaId) {
        return;
      }
      const res = await parkingPlacePage(this.queryData);
      if (res) {
        const { total, list } = res;
        this.total = total;
        this.blockList = list;
        this.idList = [];
      }
    },
    pageSizeChange(num) {
      this.queryData.pageSize = num;
      this.getData();
    },
    showInfo(info) {
      if (this.isEdit) {
        return;
      }
      this.currentInfo = info;
      this.showInfoFlag = true;
    },
    async parkingPlaceSortTypeList() {
      const res = await parkingPlaceSortTypeList();
      let list = res || [];
      this.sortList = this.$filterListTOName(list, { id: "a", name: "b" });
    },
    async getOpeningList() {
      let estateId = this.estateId || null;
      if (!estateId) {
        return;
      }
      const res = await getOpeningList(estateId);
      if (res) {
        let list = res || [];
        let arr = [];
        list.forEach(e => {
          if (e.activityType == 1) {
            arr.push(e);
          }
        });
        this.activityList = this.$filterListTOName(arr, {
          id: "id",
          name: "activityName"
        });
      }
    },
    async getMarketActivity() {
      let id = this.carActiveId;
      if (!id) {
        return;
      }
      let query = {
        status: 1
      };
      const res = await getMarketActivity(id, query);
      if (res) {
        let list = res || [];
        let arr = [];
        list.forEach(e => {
          if (e.status == 1) {
            arr.push(e);
          }
        });
        this.marketList = this.$filterListTOName(arr, {
          id: "id",
          name: "giftBagName"
        });
      }
    },
    quitChoose() {
      this.isEdit = false;
      this.idList = [];
      this.initCheck();
    },
    async selectState(e) {
      if (!e && e !== false) {
        return;
      }
      let list = this.idList;
      if (!list.length) {
        return;
      }
      let query = {
        parkingPlaceIdList: list,
        salesStatus: e
      };
      const res = await parkingPlaceBatchSalesStatus(query);
      if (res) {
        this.$showSuccess("操作成功");
        this.getData();
        this.$root.$emit("updateOpeningMenu");
      }
    },
    async parkingPlaceBatchAvailable(e) {
      if (!e && e !== false) {
        return;
      }
      let list = this.idList;
      if (!list.length) {
        return;
      }
      let query = {
        parkingPlaceIdList: list,
        available: e
      };
      const res = await parkingPlaceBatchAvailable(query);
      if (res) {
        this.$showSuccess("操作成功");
        this.getData();
        this.$root.$emit("updateOpeningMenu");
      }
    },
    async parkingPlaceBatchOpenQuotation(e) {
      if (!e && e !== false) {
        return;
      }
      let list = this.idList;
      if (!list.length) {
        return;
      }
      let query = {
        parkingPlaceIdList: list,
        openQuotationId: e
      };
      const res = await parkingPlaceBatchOpenQuotation(query);
      if (res) {
        this.$showSuccess("操作成功");
        this.getData();
        this.$root.$emit("updateOpeningMenu");
      }
    },
    async parkingPlaceBatchPromotional() {
      let list = this.idList;
      if (!list.length) {
        return;
      }
      let query = {
        parkingPlaceIdList: list,
        promotionalIdList: this.promotionalIdList
      };
      const res = await parkingPlaceBatchPromotional(query);
      if (res) {
        this.$showSuccess("操作成功");
        this.getData();
        this.$root.$emit("updateOpeningMenu");
      }
    },
    async parkingPlaceBatchDelete() {
      let list = this.idList;
      if (!list.length) {
        return;
      }
      let query = {
        parkingPlaceIdList: list
      };
      this.$showConfirm(async () => {
        const res = await parkingPlaceBatchDelete(query);
        if (res) {
          this.$showSuccess("操作成功");
          this.getData();
          this.$root.$emit("updateOpeningMenu");
        }
      }, "是否确认删除该数据?");
    },
    openEdit() {
      this.showInfoFlag = false;
      this.showEdit = true;
    },
    chooseItem() {
      this.isEdit = true;
      this.idList = [];
    },
    itemCheck(id, checked) {
      let set = new Set(this.idList);
      if (checked) {
        set.add(id);
      } else {
        set.delete(id);
      }
      this.idList = [...set];
      this.initCheck();
    },
    findId(id) {
      let flag = false;
      flag = this.idList.find(e => e == id);
      return !!flag;
    }
  }
};
</script>

<style lang="scss" scoped>
.openingBlock {
  padding: 0 20px;
  flex: 1;
  .header {
    border-bottom: 1px solid #eee;
    height: 60px;
    .total {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .block-box {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
  ::v-deep {
    .el-select {
      overflow: hidden;
    }
    .el-select__tags {
      flex-wrap: nowrap;
    }
  }
}
</style>
