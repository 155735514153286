<template>
  <div class="openingMenu dao-main pd20">
    <div class="header mb24">
      <div class="x mb16">
        <el-cascader
          style="width:100%"
          class="cascader"
          ref="areaTree"
          v-model="queryData.areaId"
          :options="parkingTreeData"
          :props="{
            label: 'name',
            value: 'id',
            emitPath: false
          }"
          placeholder="区域"
          @change="getTreeName"
        ></el-cascader>
      </div>
      <el-input
        v-model="parkingPlaceNo"
        placeholder="输入车位号搜索"
        size="medium"
      >
        <el-button slot="append" @click="search">
          搜索
        </el-button>
      </el-input>
    </div>
    <div>
      <menuItem
        :options="saleOptions"
        @getChecked="e => getChecked(e, 'salesStatusList')"
      />
      <menuItem
        :options="carOptions"
        @getChecked="e => getChecked(e, 'parkingPlaceTypeList')"
      />
      <menuItem
        :options="activityOptions"
        @getChecked="e => getChecked(e, 'openQuotationIdList')"
      />
    </div>
    <div class="x3 aic">
      <el-button icon="el-icon-plus" size="medium" @click="showEdit = true">
        创建车位
      </el-button>
      <el-button
        icon="el-icon-upload2"
        type="primary"
        size="medium"
        @click="showImport = true"
      >
        批量导入车位
      </el-button>
    </div>
    <carImport v-model="showImport" />
    <editCar :visible.sync="showEdit" />
  </div>
</template>

<script>
import { enumeration } from "@/config";
import { parkingTree, parkingPlaceFilter } from "@/api/opening";

import menuItem from "./menuItem";
import carImport from "./carImport";
import editCar from "./editCar";
const queryData = {
  areaId: "",
  parkingPlaceNo: "", // 车位号
  salesStatusList: [], //销售状态
  parkingPlaceTypeList: [], //车位类型
  openQuotationIdList: [] //开盘活动ID
};
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    menuItem,
    carImport,
    editCar
  },
  data() {
    return {
      showImport: false,
      showEdit: false,
      queryData: JSON.parse(JSON.stringify(queryData)),
      saleOptions: {
        title: "销售状态",
        type: 2,
        list: []
      },
      carOptions: {
        title: "车位类型",
        type: 2,
        list: []
      },
      activityOptions: {
        title: "开盘活动",
        title2: "可售车位/总车位数",
        type: 1,
        list: []
      },
      openingCarStateObj: enumeration.openingCarStateObj,
      parkingTreeData: [],
      parkingPlaceNo: ""
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  watch: {
    queryData: {
      deep: true,
      handler(e) {
        this.$store.commit("SET_OPENING_CAR_QUERY", this.$deepClone(e));
      }
    }
  },
  created() {
    this.parkingTree();
    this.$root.$off("updateOpeningMenu");
    this.$root.$on("updateOpeningMenu", this.parkingTree);
  },
  methods: {
    search() {
      this.queryData.parkingPlaceNo = this.parkingPlaceNo;
      this.queryData.pageIndex = 1;
      this.$store.commit(
        "SET_OPENING_CAR_QUERY",
        this.$deepClone(this.queryData)
      );
    },
    getTreeName() {
      this.parkingPlaceNo = "";
      this.queryData.parkingPlaceNo = "";
      this.getTreeNameById();
    },
    getTreeNameById() {
      let tree = this.parkingTreeData;
      let id = this.queryData.areaId;
      let names = this.$getTreeNameById(tree, id, "name", true);
      this.$store.commit("SET_CAR_AREAS", names);
      this.parkingPlaceFilter();
    },
    async parkingTree() {
      let query = {
        estateId: this.estateId
      };
      const res = await parkingTree(query);
      if (res) {
        let item = res[0] || {};
        let id = item.id;
        if (id) {
          if (item.children) {
            item = item.children[0];
            id = item.id;
          }
          this.queryData.areaId = this.queryData.areaId || id;
        }
        this.parkingTreeData = res || [];
        this.getTreeNameById();
      }
    },
    async parkingPlaceFilter() {
      let { areaId } = this.queryData;
      if (!areaId) {
        return;
      }
      let query = {
        areaId
      };
      const res = await parkingPlaceFilter(query);
      if (res) {
        const { openQuotationList, typeList, salesStatusList } = res;
        this.saleOptions.list = this.filterList(salesStatusList, {
          hasColor: true,
          hasName2: true
        });
        this.carOptions.list = this.filterList(typeList, {
          hasName2: true
        });
        this.activityOptions.list = this.filterList(openQuotationList, {
          hasNum: true
        });
        this.setActivetyId();
      }
    },
    filterList(list, { hasColor, hasName2, hasNum }) {
      let arr = [];
      list = list || [];
      list.forEach(e => {
        let item = {
          id: e.id,
          name: e.displayName
        };
        if (hasColor) {
          let colorObj = this.openingCarStateObj[e.id] || {};
          item.color = colorObj.color || "";
        }
        if (hasName2) {
          item.name2 = e.count;
        }
        if (hasNum) {
          item.num = `${e.count}/${e.totalCount}`;
        }
        arr.push(item);
      });
      return arr;
    },
    getChecked(e, name) {
      this.queryData[name] = e;
      this.queryData.pageIndex = 1;
      this.$store.commit(
        "SET_OPENING_CAR_QUERY",
        this.$deepClone(this.queryData)
      );
      this.setActivetyId();
    },
    setActivetyId() {
      let id = "";
      let checkList = this.queryData.openQuotationIdList;
      let activeList = this.activityOptions.list;
      if (checkList.length == 1) {
        id = checkList[0];
      } else if (activeList.length == 1) {
        id = activeList[0].id;
      }
      this.$store.commit("SET_CAR_ACTIVE_ID", id);
    }
  }
};
</script>

<style lang="scss" scoped>
.openingMenu {
  width: 290px;
  ::v-deep {
    .el-button--medium {
      padding: 10px;
    }
  }
}
</style>
