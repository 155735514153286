var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block y3 pointer",
    style: {
      'background-color': _vm.colorObj.bgColor
    }
  }, [_vm.isEdit ? _c('el-checkbox', {
    staticClass: "checkbox",
    on: {
      "change": _vm.getChecked
    },
    model: {
      value: _vm.itemChecked,
      callback: function callback($$v) {
        _vm.itemChecked = $$v;
      },
      expression: "itemChecked"
    }
  }) : _vm._e(), !_vm.isEdit && _vm.options.bidLocked ? _c('img', {
    staticClass: "block-icon",
    attrs: {
      "src": require("../../.././../assets/icon_zzjp.png")
    }
  }) : _vm._e(), !_vm.isEdit && !_vm.options.bidLocked && _vm.options.subscription ? _c('img', {
    staticClass: "block-icon",
    attrs: {
      "src": require("../../.././../assets/dingdan.png")
    }
  }) : _vm._e(), !_vm.isEdit && !_vm.options.bidLocked && !_vm.options.subscription && _vm.options.promotional ? _c('img', {
    staticClass: "block-icon",
    attrs: {
      "src": require("../../.././../assets/icon_libao_xiao.png")
    }
  }) : _vm._e(), _c('p', {
    staticClass: "fs18 bold lh28",
    style: {
      color: _vm.colorObj.color
    },
    attrs: {
      "salesStatus": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.options.parkingPlaceNo) + " ")]), _c('div', [_c('p', {
    staticClass: "color9 lh24"
  }, [_vm._v(" " + _vm._s(_vm.options.discountAfterPrice ? "".concat(_vm.options.discountAfterPrice, "\u4E07") : "价格待定") + " ")]), _vm.options.bidLocked ? _c('p', {
    staticClass: "red lh24"
  }, [_vm._v(" " + _vm._s(_vm.options.lockedTargetType === 2 ? "认购" : "竞拍") + "锁定 ")]) : _c('p', {
    staticClass: "color9 lh24"
  }, [_vm._v(" " + _vm._s(_vm.options.subscription ? "\u5DF2\u8BA4\u8D2D" : "".concat(_vm.options.collectionCount, "\u4EBA\u6536\u85CF")) + " ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }