import request from "@/utils/request";
/**网上开盘 */

/***************************      房源管理/车位管理       ***************************/

// 车位批量导入
export const importParkingPlace = (params, data) =>
  request({
    url: `/api/v1/platform/building/importParkingPlace`,
    method: "post",
    dataType: "formData",
    params,
    data,
    showLoading: false
  });

// 车位类型列表
export const parkingPlaceParkingPlaceTypeList = () =>
  request({
    url: `/api/v1/platform/building/parkingPlaceParkingPlaceTypeList`,
    method: "get"
  });

// 车位状态列表
export const parkingPlaceSalesStatusList = () =>
  request({
    url: `/api/v1/platform/building/parkingPlaceSalesStatusList`,
    method: "get"
  });

// 车位基本信息
export const parkingPlaceBasicInfo = id =>
  request({
    url: `/api/v1/platform/building/parkingPlaceBasicInfo/${id}`,
    method: "get"
  });

// 车位详细信息
export const parkingPlaceDetailInfo = id =>
  request({
    url: `/api/v1/platform/building/parkingPlaceDetailInfo/${id}`,
    method: "get"
  });

// 车位列表分页查询
export const parkingPlacePage = data =>
  request({
    url: `/api/v1/platform/building/parkingPlacePage`,
    method: "get",
    data
  });

// 车位批量 上架/下架
export const parkingPlaceBatchAvailable = params =>
  request({
    url: `/api/v1/platform/building/parkingPlaceBatchAvailable`,
    method: "put",
    params
  });

// 车位批量销控
export const parkingPlaceBatchSalesStatus = params =>
  request({
    url: `/api/v1/platform/building/parkingPlaceBatchSalesStatus`,
    method: "put",
    params
  });

// 车位批量设置营销活动
export const parkingPlaceBatchPromotional = params =>
  request({
    url: `/api/v1/platform/building/parkingPlaceBatchPromotional`,
    method: "put",
    params
  });

// 车位批量关联开盘活动
export const parkingPlaceBatchOpenQuotation = params =>
  request({
    url: `/api/v1/platform/building/parkingPlaceBatchOpenQuotation`,
    method: "put",
    params
  });

// 车位批量删除
export const parkingPlaceBatchDelete = params =>
  request({
    url: `/api/v1/platform/building/parkingPlaceBatchDelete`,
    method: "put",
    params
  });

// 车位分区结构
export const parkingTree = data =>
  request({
    url: `/api/v1/platform/building/parkingTree`,
    method: "get",
    data
  });

// 车位排序方式
export const parkingPlaceSortTypeList = () =>
  request({
    url: `/api/v1/platform/building/parkingPlaceSortTypeList`,
    method: "get"
  });

// 车位筛选器
export const parkingPlaceFilter = data =>
  request({
    url: `/api/v1/platform/building/parkingPlaceFilter`,
    method: "get",
    data
  });

//新增/编辑车位
export const mergeParkingPlace = (params, data) =>
  request({
    url: `/api/v1/platform/building/mergeParkingPlace`,
    method: "post",
    params,
    data
  });

// 房源楼栋单元楼层树
export const houseLayoutTree = data =>
  request({
    url: `/api/v1/platform/building/houseLayoutTree`,
    method: "get",
    data
  });

// 房源列表查询
export const housePage = data =>
  request({
    url: `/api/v1/platform/building/housePage`,
    method: "get",
    data
  });

// 房源筛选器
export const houseFilter = data =>
  request({
    url: `/api/v1/platform/building/houseFilter`,
    method: "get",
    data
  });

// 房源批量导入
export const importHouse = (params, data) =>
  request({
    url: `/api/v1/platform/building/importHouse`,
    method: "post",
    dataType: "formData",
    params,
    data,
    showLoading: false
  });

// 新增/编辑房源
export const mergeHouse = (params, data) =>
  request({
    url: `/api/v1/platform/building/mergeHouse`,
    method: "post",
    params,
    data
  });

// 房源类型列表
export const houseTypeList = () =>
  request({
    url: `/api/v1/platform/building/houseTypeList`,
    method: "get"
  });

// 房源状态列表
export const houseSalesStatusList = () =>
  request({
    url: `/api/v1/platform/building/houseSalesStatusList`,
    method: "get"
  });

// 查询户型列表
export const getHouseTypeList = data =>
  request({
    url: `/api/v1/fxb/fybestate/getHouseTypeList`,
    method: "post",
    data
  });

// 房源排序方式列表
export const houseSortTypeList = () =>
  request({
    url: `/api/v1/platform/building/houseSortTypeList`,
    method: "get"
  });

// 房源详细信息(包括认购和营销详细信息)
export const houseDetailInfo = id =>
  request({
    url: `/api/v1/platform/building/houseDetailInfo/${id}`,
    method: "get"
  });

// 房源批量 上架/下架
export const houseBatchAvailable = params =>
  request({
    url: `/api/v1/platform/building/houseBatchAvailable`,
    method: "put",
    params
  });

// 房源批量 删除
export const houseBatchDelete = params =>
  request({
    url: `/api/v1/platform/building/houseBatchDelete`,
    method: "put",
    params
  });

// 房源批量关联开盘活动
export const houseBatchOpenQuotation = params =>
  request({
    url: `/api/v1/platform/building/houseBatchOpenQuotation`,
    method: "put",
    params
  });

// 房源批量设置营销活动
export const houseBatchPromotional = params =>
  request({
    url: `/api/v1/platform/building/houseBatchPromotional`,
    method: "put",
    params
  });

// 房源批量销控
export const houseBatchSalesStatus = params =>
  request({
    url: `/api/v1/platform/building/houseBatchSalesStatus`,
    method: "put",
    params
  });

// 房源批量关联户型
export const houseBatchRelationHouseLayout = params =>
  request({
    url: `/api/v1/platform/building/houseBatchRelationHouseLayout`,
    method: "put",
    params
  });

// 房源基本信息
export const houseBasicInfo = id =>
  request({
    url: `/api/v1/platform/building/houseBasicInfo/${id}`,
    method: "get"
  });

// 获取车位销控表地址
export const downloadParkingTemplate = id =>
  request({
    url: `/api/v1/platform/building/downloadParkingTemplate`,
    method: "get"
  });
