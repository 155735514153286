var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "x"
  }, [_c('openingMenu', {
    staticClass: "mr10"
  }), _c('openingBlock')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }