<template>
  <div
    class="block y3 pointer"
    :style="{ 'background-color': colorObj.bgColor }"
  >
    <el-checkbox
      v-if="isEdit"
      class="checkbox"
      v-model="itemChecked"
      @change="getChecked"
    ></el-checkbox>
    <img
      v-if="!isEdit && options.bidLocked"
      class="block-icon"
      src="../../.././../assets/icon_zzjp.png"
    />
    <img
      v-if="!isEdit && !options.bidLocked && options.subscription"
      class="block-icon"
      src="../../.././../assets/dingdan.png"
    />
    <img
      v-if="
        !isEdit &&
          !options.bidLocked &&
          !options.subscription &&
          options.promotional
      "
      class="block-icon"
      src="../../.././../assets/icon_libao_xiao.png"
    />
    <p class="fs18 bold lh28" :style="{ color: colorObj.color }" salesStatus>
      {{ options.parkingPlaceNo }}
    </p>
    <div>
      <p class="color9 lh24">
        {{
          options.discountAfterPrice
            ? `${options.discountAfterPrice}万`
            : "价格待定"
        }}
      </p>
      <p class="red lh24" v-if="options.bidLocked">
        {{ options.lockedTargetType === 2 ? "认购" : "竞拍" }}锁定
      </p>
      <p class="color9 lh24" v-else>
        {{
          options.subscription ? `已认购` : `${options.collectionCount}人收藏`
        }}
      </p>
    </div>
  </div>
</template>

<script>
import { enumeration } from "@/config";

export default {
  props: {
    options: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    },
    checked: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      openingCarStateObj: enumeration.openingCarStateObj,
      src: "",
      itemChecked: false
    };
  },
  computed: {
    colorObj() {
      const { options, openingCarStateObj } = this;
      let salesStatus = options.available ? options.salesStatus : "UN_SHELVE";
      return openingCarStateObj[salesStatus] || {};
    }
  },
  watch: {
    isEdit(e) {
      if (e) {
        this.itemChecked = this.checked;
      }
    },
    checked() {
      this.itemChecked = this.checked;
    }
  },
  methods: {
    getChecked() {
      this.$emit("itemCheck", this.options.id, this.itemChecked);
    }
  }
};
</script>

<style lang="scss" scoped>
.block {
  height: 100px;
  //width: 100px;
  border-radius: 3px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  .block-icon {
    min-width: 20px;
    min-height: 20px;
    max-width: 24px;
    max-height: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }
  .checkbox {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }
}
</style>
