<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    class="f-dialog"
    :before-close="close"
    width="605px"
  >
    <el-form :model="formData" class="form" ref="form" label-width="102px">
      <el-form-item
        label="开盘活动："
        prop="openQuotationId"
        :rules="[$formRules.required()]"
      >
        <el-select
          size="medium"
          v-model="formData.openQuotationId"
          style="width: 100%"
          @change="getMarketActivity"
        >
          <el-option
            v-for="(item, index) in activityList"
            :label="item.activityName"
            :value="item.id"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="类型:"
        prop="parkingPlaceType"
        :rules="[$formRules.required()]"
      >
        <el-radio-group v-model="formData.parkingPlaceType" size="medium">
          <el-radio-button
            :label="item.a"
            v-for="(item, index) in carTypeList"
            :key="index"
          >
            {{ item.b }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="楼层："
        prop="floorName"
        :rules="[$formRules.required()]"
      >
        <el-autocomplete
          class="width"
          size="medium"
          v-model="formData.floorName"
          :fetch-suggestions="querySearchFloor"
          placeholder="请输入"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item
        label="区域："
        prop="areaName"
        :rules="[$formRules.required()]"
      >
        <el-autocomplete
          class="width"
          size="medium"
          v-model="formData.areaName"
          :fetch-suggestions="querySearchArea"
          placeholder="请输入"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item
        label="车位号："
        prop="parkingPlaceNo"
        :rules="[$formRules.required()]"
      >
        <el-input
          size="medium"
          v-model="formData.parkingPlaceNo"
          placeholder="请输入"
        >
          <template slot="append">号</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="建筑面积："
        prop="buildingArea"
        :rules="[$formRules.required(), $formRules.number]"
      >
        <el-input v-model="formData.buildingArea" placeholder="请输入">
          <template slot="append">㎡</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="实得面积："
        prop="actualArea"
        :rules="[$formRules.number]"
      >
        <el-input v-model="formData.actualArea" placeholder="请输入">
          <template slot="append">㎡</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="销售总价："
        prop="discountAfterPrice"
        :rules="[$formRules.required(), $formRules.number]"
      >
        <el-input v-model="formData.discountAfterPrice" placeholder="请输入">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="描述：" prop="description">
        <div>
          <el-input
            type="textarea"
            :rows="3"
            v-model="formData.description"
            placeholder="请输入"
          >
          </el-input>
        </div>
      </el-form-item>
      <div class="promotional-title">
        营销活动<em>空值或0值时，代表不支持该营销活动</em>
      </div>
      <el-form-item
        label="优惠价1："
        prop="discountPrice1"
        :rules="[$formRules.number]"
      >
        <el-input v-model="formData.discountPrice1" placeholder="请输入">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="优惠价2："
        prop="discountPrice2"
        :rules="[$formRules.number]"
      >
        <el-input v-model="formData.discountPrice2" placeholder="请输入">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="优惠价3："
        prop="discountPrice3"
        :rules="[$formRules.number]"
      >
        <el-input v-model="formData.discountPrice3" placeholder="请输入">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="营销礼包：" prop="promotionalIds">
        <el-select
          multiple
          style="width:100%;"
          v-model="formData.promotionalIds"
        >
          <el-option
            v-for="(item, index) in marketActivityList"
            :key="index"
            :label="item.giftBagName"
            :value="item.id"
          >
            {{ item.giftBagName }}
            <span class="red">¥{{ item.giftBagPrice }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <!--  <el-form-item label="营销礼包：" prop="promotionalIds">
        <div>
          <el-checkbox
            v-model="formData.promotionalIds"
            v-for="(item, index) in marketActivityList"
            :key="index"
            :label="item.id"
            class="width hidden"
          >
            <p>
              {{ item.giftBagName }}
              <span class="red">¥{{ item.giftBagPrice }}</span>
            </p>
          </el-checkbox>
        </div>
      </el-form-item> -->
    </el-form>
    <div slot="footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  parkingTree,
  parkingPlaceParkingPlaceTypeList,
  parkingPlaceSalesStatusList,
  parkingPlaceBasicInfo,
  mergeParkingPlace
} from "@/api/opening";

import { getOpeningList } from "@/api/openQuotationActivity";
import { getMarketActivity } from "@/api/marketActivity";

const formData = {
  actualArea: null,
  areaName: "",
  available: false,
  buildingArea: null,
  description: "",
  discountAfterPrice: null,
  discountBeforePrice: null,
  floorName: "",
  id: null,
  openQuotationId: null,
  openQuotationName: "",
  parkingPlaceNo: "",
  parkingPlaceType: "STANDARD",
  promotionalIds: [],
  salesStatus: "AVAILABLE_SALE"
};
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      formData: {},
      carTypeList: [],
      carStateList: [],
      activityList: [],
      marketActivityList: [],
      checked: false,
      parkingTreeData: []
    };
  },
  computed: {
    title() {
      let title = "创建车位";
      if (this.id) {
        title = "编辑车位";
      }
      return title;
    },
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    }
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    async init() {
      const { id } = this;
      if (id) {
        await this.getDetail();
        this.getMarketActivity();
      } else {
        this.formData = this.$deepClone(formData);
        this.$nextTick(() => {
          this.$refs.form.clearValidate();
        });
      }
      this.getOpeningList();
      this.parkingTree();
      this.parkingPlaceParkingPlaceTypeList();
      this.parkingPlaceSalesStatusList();
    },
    async parkingTree() {
      let query = {
        estateId: this.estateId
      };
      const res = await parkingTree(query);
      if (res) {
        this.parkingTreeData = res || [];
      }
    },
    querySearchFloor(queryString, cb) {
      let results = this.parkingTreeData.map(e => {
        return {
          value: e.name
        };
      });
      cb(results);
    },
    querySearchArea(queryString, cb) {
      let list = [];
      this.parkingTreeData.forEach(e => {
        if (e.name == this.formData.floorName) {
          if (e.children && e.children.length) {
            list = e.children;
          }
        }
      });
      let results = list.map(e => {
        return {
          value: e.name
        };
      });
      cb(results);
    },
    async getOpeningList() {
      let estateId = this.estateId || null;
      if (!estateId) {
        return;
      }
      const res = await getOpeningList(estateId);
      if (res) {
        let list = res || [];
        let arr = [];
        list.forEach(e => {
          if (e.activityType == 1) {
            arr.push(e);
          }
        });
        this.activityList = arr;
      }
    },
    async getMarketActivity() {
      let id = this.formData.openQuotationId;
      if (!id) {
        return;
      }
      let query = {
        status: 1
      };
      const res = await getMarketActivity(id, query);
      if (res) {
        let list = res || [];
        let arr = [];
        list.forEach(e => {
          if (e.status == 1) {
            arr.push(e);
          }
        });
        this.marketActivityList = arr;
      }
    },
    async parkingPlaceParkingPlaceTypeList() {
      const res = await parkingPlaceParkingPlaceTypeList();
      this.carTypeList = res || [];
    },
    async parkingPlaceSalesStatusList() {
      const res = await parkingPlaceSalesStatusList();
      this.carStateList = res || [];
    },
    async getDetail() {
      const res = await parkingPlaceBasicInfo(this.id);
      if (res) {
        this.formData = this.$deepClone(res);
        this.formData.discountAfterPrice = this.$calc.accMulti(
          res.discountAfterPrice,
          10000
        );
      }
    },
    submit() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return;
        }
        let query = {
          estateId: this.estateId
        };
        const res = await mergeParkingPlace(query, this.formData);
        if (res) {
          this.$showSuccess("操作成功");
          this.close();
          this.$root.$emit("updateOpeningMenu");
          this.$store.commit("SET_REFRESH_FLAG");
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.promotional-title {
  border-bottom: 1px solid #eee;
  font-size: 16px;
  color: #333;
  padding: 0 0 15px 10px;
  margin-bottom: 20px;
  em {
    font-style: normal;
    margin-left: 10px;
    font-size: 13px;
    color: #aaa;
  }
}
::v-deep .el-radio-button--medium .el-radio-button__inner {
  padding: 10px 10px;
}
::v-deep .el-form-item {
  margin-bottom: 15px;
}
</style>
