<template>
  <div class="info">
    <div class="top-bar">
      车位详情
      <i class="el-icon-close" @click="$emit('close')"></i>
    </div>
    <div class="content" v-loading="loading">
      <div
        class="title"
        v-if="
          buildingInfo.floorName ||
            buildingInfo.areaName ||
            buildingInfo.parkingPlaceNo
        "
      >
        {{ buildingInfo.floorName }} - {{ buildingInfo.areaName }} -
        {{ buildingInfo.parkingPlaceNo }} 号车位
      </div>
      <div class="info-cell" v-if="buildingInfo.id">
        <div class="info-cell-title">
          基本信息
          <span class="status" :class="{ grey: !buildingInfo.available }">
            {{ buildingInfo.available ? "上架中" : "已下架" }}
          </span>
        </div>
        <div class="cell">
          <div class="item">
            <div class="label">销售状态：</div>
            <div
              class="value"
              :style="{
                color: carStateObj.color
              }"
            >
              {{ carStateObj.name }}
            </div>
          </div>
          <div class="item">
            <div class="label">开盘活动：</div>
            <div class="value">{{ buildingInfo.openQuotationName }}</div>
          </div>
          <div class="item">
            <div class="label">车位类型：</div>
            <div class="value">{{ buildingInfo.parkingPlaceTypeDesc }}</div>
          </div>
          <div class="item">
            <div class="label">建筑面积：</div>
            <div class="value">{{ buildingInfo.buildingArea }}㎡</div>
          </div>
          <div class="item">
            <div class="label">实得面积：</div>
            <div class="value">
              {{
                buildingInfo.actualArea ? `${buildingInfo.actualArea}㎡` : "-"
              }}
            </div>
          </div>
          <div class="item">
            <div class="label">计划收款额：</div>
            <div class="value red" v-if="buildingInfo.discountAfterPrice">
              ¥{{ (buildingInfo.discountAfterPrice * 10000) | toThousands }}
            </div>
            <div class="value red" v-else>价格待定</div>
          </div>
          <div class="item" v-if="buildingInfo.description">
            <div class="label">备注信息：</div>
            <div class="value">{{ buildingInfo.description }}</div>
          </div>
        </div>
        <div
          class="cell"
          v-if="
            buildingInfo.discountPrice1 ||
              buildingInfo.discountPrice2 ||
              buildingInfo.discountPrice3
          "
        >
          <div class="item" v-if="buildingInfo.discountPrice1">
            <div class="label">优惠价格1：</div>
            <div class="value red">
              ¥{{ buildingInfo.discountPrice1 | toThousands }}
            </div>
          </div>
          <div class="item" v-if="buildingInfo.discountPrice2">
            <div class="label red">优惠价格2：</div>
            <div class="value red">
              ¥{{ buildingInfo.discountPrice2 | toThousands }}
            </div>
          </div>
          <div class="item" v-if="buildingInfo.discountPrice3">
            <div class="label red">优惠价格3：</div>
            <div class="value">
              ¥{{ buildingInfo.discountPrice3 | toThousands }}
            </div>
          </div>
          <div v-for="(item, index) in promotions" :key="index" class="mt10">
            <div class="item">
              <div class="label">营销礼包：</div>
              <div class="value red" v-if="item.subscriptionTotalPrice">
                ￥{{ item.subscriptionTotalPrice | toThousands }}
              </div>
              <div class="value red" v-else>价格待定</div>
            </div>
            <div class="item">
              <div class="label"></div>
              <div class="value">{{ item.promotionalName }}</div>
            </div>
          </div>
        </div>
        <div class="cell" v-if="collectionUsers.length">
          <div class="item">
            <div class="label">收藏用户</div>
            <div class="value">{{ collectionUsers.length }}个</div>
          </div>
          <div class="user-list mt20">
            <div
              class="user-cell"
              style="cursor:pointer"
              v-for="(item, index) in collectionUsersList"
              :key="index"
            >
              <el-popover placement="top-start" width="220" trigger="hover">
                <div>
                  <p>认购客户：{{ item.name }}</p>
                  <p>联系电话：{{ item.cellphone | formatPhone }}</p>
                </div>
                <img slot="reference" class="user-cell" :src="item.avatarUrl" />
              </el-popover>
            </div>
          </div>
          <div
            class="load-more"
            @click="showMoreUser"
            v-if="
              collectionUsers.length > 16 && collectionUsersList.length <= 16
            "
          >
            加载更多
          </div>
        </div>
      </div>
      <div class="info-cell" v-if="subscriptionParkingBookingOrder.id">
        <div class="info-cell-title">认购订单</div>
        <div class="cell">
          <div class="item">
            <div class="label">认购单号</div>
            <div class="value">
              <svg-icon
                icon-class="copy"
                class="copy-icon pointer"
                @click="copyText(subscriptionParkingBookingOrder.orderNo)"
              ></svg-icon>
              {{ subscriptionParkingBookingOrder.orderNo }}
            </div>
          </div>
          <div class="item">
            <div class="label">认购总额</div>
            <div class="value red">
              ¥{{ subscriptionParkingBookingOrder.orderTotalPrice }}
            </div>
          </div>
          <div class="item">
            <div class="label">优惠活动</div>
            <div class="value">
              {{
                subscriptionParkingBookingOrder.giftBagName ||
                  (subscriptionParkingBookingOrder.orderTotalPriceDesc ==
                  "认购总额"
                    ? "无"
                    : subscriptionParkingBookingOrder.orderTotalPriceDesc)
              }}
            </div>
          </div>
          <div class="item">
            <div class="label">金融方案</div>
            <div class="value">已申请</div>
          </div>
        </div>
        <div class="cell">
          <div class="item">
            <div class="label">认购金</div>
            <div class="value red">
              ¥{{ subscriptionParkingBookingOrder.orderPrice | toThousands }}
            </div>
          </div>
          <div class="item">
            <div class="label">支付方式</div>
            <div class="value">
              {{ subscriptionParkingBookingOrder.orderWayDesc }}
            </div>
          </div>
          <div class="item">
            <div class="label">认购时间</div>
            <div class="value">
              {{ subscriptionParkingBookingOrder.inDate }}
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="item">
            <div class="label">认购人</div>
            <div class="value">
              {{ subscriptionParkingBookingOrder.customerName }}
            </div>
          </div>
          <div class="item">
            <div class="label">身份证号</div>
            <div class="value">
              {{ subscriptionParkingBookingOrder.customerIdcard }}
            </div>
          </div>
          <div class="item">
            <div class="label">手机号码</div>
            <div class="value">
              {{ subscriptionParkingBookingOrder.customerPhone | formatPhone }}
            </div>
          </div>
        </div>
      </div>
      <div class="info-cell" v-if="bidTargetInfo.biddingAmount">
        <div class="info-cell-title">正在竞拍</div>
        <div class="cell">
          <div class="item" v-if="bidTargetInfo.biddingAmount">
            <div class="label">起拍价：</div>
            <div class="value">
              ¥{{ bidTargetInfo.biddingAmount | toThousands }}
            </div>
          </div>
          <div class="item" v-if="bidTargetInfo.biddingStepAmount">
            <div class="label">加价幅度：</div>
            <div class="value">
              ¥{{ bidTargetInfo.biddingStepAmount | toThousands }}
            </div>
          </div>
          <div class="item" v-if="bidTargetInfo.delay">
            <div class="label">延时周期：</div>
            <div class="value">{{ bidTargetInfo.delay }}分钟</div>
          </div>
          <div class="item" v-if="bidTargetInfo.ensureAmount">
            <div class="label">保证金：</div>
            <div class="value">
              ¥{{ bidTargetInfo.ensureAmount | toThousands }}
            </div>
          </div>
          <div class="item" v-if="bidTargetInfo.startDate">
            <div class="label">开始时间：</div>
            <div class="value">{{ bidTargetInfo.startDate }}</div>
          </div>
          <div class="item" v-if="bidTargetInfo.endDelayDate">
            <div class="label">结束时间：</div>
            <div class="value">{{ bidTargetInfo.endDelayDate }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button
        class="flex1"
        size="medium"
        type="info"
        @click="parkingPlaceBatchAvailable"
      >
        {{ buildingInfo.available ? "下架" : "上架" }}
      </el-button>
      <el-button class="flex1" type="primary" size="medium" @click="openEdit">
        编辑车位
      </el-button>
    </div>
  </div>
</template>

<script>
import { enumeration } from "@/config";
import {
  parkingPlaceDetailInfo,
  parkingPlaceBatchAvailable
} from "@/api/opening";
import { copyText } from "@/utils";

export default {
  props: {
    id: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      openingCarStateObj: enumeration.openingCarStateObj,
      buildingInfo: {},
      subscriptionParkingBookingOrder: {},
      collectionUsers: [],
      collectionUsersList: [],
      promotions: [],
      bidTargetInfo: {},
      loading: true
    };
  },
  computed: {
    carStateObj() {
      const { openingCarStateObj, buildingInfo } = this;
      return openingCarStateObj[buildingInfo.salesStatus] || {};
    }
  },
  created() {
    this.getDetail();
  },
  methods: {
    copyText,
    openEdit() {
      this.$emit("openEdit");
    },
    async getDetail() {
      const { id } = this;
      if (!id) {
        return;
      }
      const res = await parkingPlaceDetailInfo(id);
      this.loading = false;
      if (res) {
        const {
          buildingInfo,
          subscriptionParkingBookingOrder,
          collectionUsers,
          promotions,
          bidTargetInfo
        } = res;
        this.buildingInfo = buildingInfo || {};
        this.subscriptionParkingBookingOrder =
          subscriptionParkingBookingOrder || {};
        this.collectionUsers = collectionUsers || [];
        // 默认只显示16个用户
        this.collectionUsersList = this.collectionUsers.slice(0, 16);
        this.promotions = promotions || [];
        this.bidTargetInfo = bidTargetInfo || {};
      }
    },
    showMoreUser() {
      // 加载更多，收藏用户
      this.collectionUsersList = this.collectionUsers;
    },
    async parkingPlaceBatchAvailable() {
      let params = {
        parkingPlaceIdList: [this.buildingInfo.id],
        available: !this.buildingInfo.available
      };
      const res = await parkingPlaceBatchAvailable(params);
      if (res) {
        this.$showSuccess("操作成功");
        this.getDetail();
        this.$store.commit("SET_REFRESH_FLAG");
        this.$root.$emit("updateOpeningMenu");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.copy-icon {
  margin-right: 10px;
  cursor: pointer;
  &:active {
    opacity: 0.75;
  }
}
.info {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .w240 {
    width: 240px;
    text-align: right;
  }
  .state {
    margin-top: 36px;
    height: 80px;
    border-radius: 3px;
    padding: 20px;
  }
  .user-list {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    .user-cell {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}
.content {
  flex: 1;
  padding: 20px;
  overflow: auto;
}
.top-bar {
  @include flex-ycb;
  padding: 0 20px;
  height: 44px;
  background-color: #eee;
  font-size: 15px;
  font-weight: bold;
  color: #333;
  line-height: 20px;
  i {
    font-weight: normal;
    font-size: 20px;
    color: #aaa;
    cursor: pointer;
    &:active {
      opacity: 0.75;
    }
  }
}
.footer {
  @include flex-xyc;
  padding: 20px;
}
.title {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  text-align: center;
  padding: 10px 0 20px 0;
}
.info-cell {
  .info-cell-title {
    @include flex-ycb;
    font-size: 16px;
    color: #333;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
    .status {
      font-size: 14px;
      &.grey {
        color: #aaa;
      }
    }
  }
  .cell {
    padding: 20px 0;
  }
  .cell + .cell {
    border-top: 1px dashed #eee;
  }
  .item {
    @include flex-ycb;
    color: #333;
    .label {
      color: #aaa;
    }
    .value {
      @include flex-yc;
    }
  }
  .item + .item {
    margin-top: 10px;
  }
}
.promotion-con {
  text-align: right;
  margin: 10px 0;
}
.load-more {
  text-align: center;
  color: #999;
  padding: 15px 0;
  cursor: pointer;
  &:hover {
    color: #333;
  }
}
::v-deep .el-button--info {
  color: #333;
}
</style>
