var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "item-box mb24"
  }, [_c('div', {
    staticClass: "x3 aic h40 mb10",
    staticStyle: {
      "border-bottom": "1px solid #eee"
    }
  }, [_c('div', {
    staticClass: "x aic"
  }, [_c('p', {
    staticClass: "fs16 bold"
  }, [_vm._v(_vm._s(_vm.options.title))]), _c('p', {
    staticClass: "color9 ml8"
  }, [_vm._v(_vm._s(_vm.options.title2))])]), _c('p', {
    staticClass: "color9 pointer",
    on: {
      "click": function click($event) {
        _vm.checkedList = [];
      }
    }
  }, [_c('i', {
    staticClass: "el-icon-refresh-left"
  }), _vm._v(" 重置 ")])]), _c('el-checkbox-group', {
    staticClass: "mt15",
    class: "fr".concat(_vm.options.type),
    model: {
      value: _vm.checkedList,
      callback: function callback($$v) {
        _vm.checkedList = $$v;
      },
      expression: "checkedList"
    }
  }, _vm._l(_vm.options.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "x3 aic"
    }, [_c('div', {
      staticClass: "flex1 x3 aic"
    }, [_c('div', {
      staticClass: "x aic"
    }, [_c('el-checkbox', {
      staticClass: "checkbox",
      attrs: {
        "label": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('p', {
      staticClass: "fs14 ml4 color3"
    }, [_vm._v(" " + _vm._s(item.name2) + " ")])], 1), _c('p', {
      staticClass: "name2 fs14 color9"
    }, [_vm._v(_vm._s(item.num))])]), item.color ? _c('div', {
      staticClass: "spot",
      style: "background-color:".concat(item.color, ";")
    }) : _vm._e()]);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }